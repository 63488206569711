<template>
  <section class="page-container">
    <div ref="toolbar" class="toolbar">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="addAisle = true"> 新增 </el-button>
        </el-form-item>
        <el-form-item>
          <a href="/mucy-pay.apk"> 下载监听APP </a>
        </el-form-item>
      </el-form>
      <el-table
        :data="payAisle"
        v-loading = "loading"
        element-loading-background = "rgba(0, 0, 0, 0.5)"
        element-loading-text = "数据正在加载中"
        element-loading-spinner = "el-icon-loading"
        style="width: 100%">
        <el-table-column
          prop="name"
          label="名称">
        </el-table-column>
        <el-table-column
          label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
          label="更新时间">
          <template slot-scope="scope">
            {{ scope.row.updateTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
          label="类型"
          width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 0">支付宝</span>
            <span v-if="scope.row.type === 1">微信</span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">可用</span>
            <span v-if="scope.row.status === 1">禁用</span>
          </template>
        </el-table-column>
        <el-table-column
          label="监听类型"
          width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.isCode === 0">二维码APP监听</span>
            <span v-if="scope.row.isCode === 1">免挂机监听</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="cookie"
          label="支付宝Cookie">
        </el-table-column>
        <el-table-column
          prop="cToken"
          label="支付宝cToken">
        </el-table-column>
        <el-table-column
          prop="billUserId"
          label="支付宝用户ID">
        </el-table-column>
        <el-table-column
          prop="usdtToken"
          label="USDTToken">
        </el-table-column>
        <el-table-column
          label="操作"
          width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="getAisleBy(scope.row.id)">修改</el-button>
            <el-button type="text" v-if="scope.row.isCode === 0" @click="getCodeList(scope.row.id)">配置二维码</el-button>
            <el-button type="text" @click="deleteAisle(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="新增通道"
      :visible.sync="addAisle"
      width="30%"
      :before-close="addAisleClose">
      <div>
        <el-form :inline="true" :model="addAisleForm">
          <el-form-item label="通道名称：">
            <el-input v-model="addAisleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="通道类型：">
            <el-radio-group v-model="addAisleForm.type">
              <el-radio label="0">支付宝</el-radio>
              <el-radio label="1">微信</el-radio>
              <el-radio label="2">USDT</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="监听类型：" v-if="addAisleForm.type != '2'">
            <el-radio-group v-model="addAisleForm.isCode">
              <el-radio label="0">二维码App监听</el-radio>
              <el-radio label="1">免挂机</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="通道状态：">
            <el-radio-group v-model="addAisleForm.status">
              <el-radio label="0">启用</el-radio>
              <el-radio label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="支付宝Cookie：" v-if="addAisleForm.isCode === '1'">
            <el-input v-model="addAisleForm.cookie"></el-input>
          </el-form-item>
          <el-form-item label="支付宝CToken：" v-if="addAisleForm.isCode === '1'">
            <el-input v-model="addAisleForm.cToken"></el-input>
          </el-form-item>
          <el-form-item label="支付宝用户ID：" v-if="addAisleForm.type === '0'">
            <el-input v-model="addAisleForm.billUserId"></el-input>
          </el-form-item>
          <el-form-item label="USDT地址：" v-if="addAisleForm.type === '2'">
            <el-input v-model="addAisleForm.usdtToken"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAisle = false">取 消</el-button>
        <el-button type="primary" @click="addAisleSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="更新通道"
      :visible.sync="updateAisle"
      width="30%"
      :before-close="updateAisleClose">
      <div>
        <el-form :inline="true" :model="updateAisleForm">
          <el-form-item label="通道名称：">
            <el-input v-model="updateAisleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="通道类型：">
            <el-radio-group v-model="updateAisleForm.type">
              <el-radio :label="0">支付宝</el-radio>
              <el-radio :label="1">微信</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="监听类型：" v-if="addAisleForm.type != '2'">
            <el-radio-group v-model="updateAisleForm.isCode">
              <el-radio :label="0">二维码App监听</el-radio>
              <el-radio :label="1">免挂机</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="通道状态：">
            <el-radio-group v-model="updateAisleForm.status">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="支付宝Cookie：" v-if="updateAisleForm.isCode === 1">
            <el-input v-model="updateAisleForm.cookie"></el-input>
          </el-form-item>
          <el-form-item label="支付宝CToken：" v-if="updateAisleForm.isCode === 1">
            <el-input v-model="updateAisleForm.cToken"></el-input>
          </el-form-item>
          <el-form-item label="支付宝用户ID：" v-if="addAisleForm.type === '0'">
            <el-input v-model="updateAisleForm.billUserId"></el-input>
          </el-form-item>
          <el-form-item label="USDT地址：" v-if="addAisleForm.type === '2'">
            <el-input v-model="updateAisleForm.usdtToken"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateAisle = false">取 消</el-button>
        <el-button type="primary" @click="updateAisleSubmit(updateAisleForm.id)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="二维码管理"
      :visible.sync="manageCode"
      width="50%"
      :before-close="manageCodeClose">
      <div>
        <el-form :inline="true">
          <el-form-item>
            <el-button type="primary" @click="addCode = true"> 新增 </el-button>
          </el-form-item>
        </el-form>
        <el-table
          :data="codeList"
          v-loading = "codeLoading"
          element-loading-background = "rgba(0, 0, 0, 0.5)"
          element-loading-text = "数据正在加载中"
          element-loading-spinner = "el-icon-loading"
          style="width: 100%">
          <el-table-column
            prop="payUrl"
            label="二维码链接">
          </el-table-column>
          <el-table-column
            prop="price"
            label="二维码金额">
          </el-table-column>
          <el-table-column
            label="是否通用码">
            <template slot-scope="scope">
              <span v-if="scope.row.mode === 0">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="getCodeBy(scope.row.id)">修改</el-button>
              <el-button type="text" @click="deleteCode(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="codePage.total > 0" ref="pagebar" class="pagebar">
          <el-pagination
            :page-sizes="[5,10,20, 50, 100, 300]"
            :current-page="codePage.page"
            :page-size="codePage.limit"
            :total="codePage.total"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handlePageChange"
            @size-change="handleLimitChange"/>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="manageCode = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增二维码"
      :visible.sync="addCode"
      width="30%"
      :before-close="addCodeClose">
      <div>
        <el-form :inline="true" :model="addCodeForm">
          <el-form-item label="支付二维码：">
            <el-input v-model="addCodeForm.payUrl"></el-input>
          </el-form-item>
          <el-form-item label="二维码金额：">
            <el-input v-model="addCodeForm.price"></el-input>
          </el-form-item>
          <el-form-item label="是否通用：">
            <el-radio-group v-model="addCodeForm.mode">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCode = false">取 消</el-button>
        <el-button type="primary" @click="addCodeSubmit()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="更新二维码"
      :visible.sync="updateCode"
      width="30%"
      :before-close="updateCodeClose">
      <div>
        <el-form :inline="true" :model="updateCodeForm">
          <el-form-item label="支付二维码：">
            <el-input v-model="updateCodeForm.payUrl"></el-input>
          </el-form-item>
          <el-form-item label="二维码金额：">
            <el-input v-model="updateCodeForm.price"></el-input>
          </el-form-item>
          <el-form-item label="是否通用：">
            <el-radio-group v-model="updateCodeForm.mode">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateCode = false">取 消</el-button>
        <el-button type="primary" @click="updateCodeSubmit(updateCodeForm.id)">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      loading:false,
      codeLoading:false,
      payAisle:[],
      addAisle:false,
      addAisleForm:{},
      updateAisle:false,
      updateAisleForm:{},
      manageCode:false,
      codeList:[],
      addCode:false,
      codePage:{
        total:0,
        page:1,
        limit:5,
        aisleId:'',
      },
      addCodeForm:{
        mode:1
      },
      updateCode:false,
      updateCodeForm:{},
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    async list(){
      let me = this
      this.loading = true
      try{
        me.payAisle = await this.axios.get("/api/admin/pay/list")
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
      this.loading = false
    },
    async getAisleBy(id){
      let me = this
      try{
        me.updateAisleForm = await this.axios.get("/api/admin/pay/aisle/"+id)
        me.updateAisle = true
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
    },
    async getCodeList(aisleId){
      let me = this
      this.codeLoading = true
      try{
        let code = await this.axios.form("/api/admin/pay/configs/"+aisleId,me.codePage)
        me.codeList = code.configs
        me.codePage = {
          total:code.page.total,
          page:code.page.page,
          limit:code.page.limit,
          aisleId: aisleId
        }
        me.manageCode = true
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
      this.codeLoading = false
    },
    async getCodeBy(id){
      let me = this
      try{
        me.updateCodeForm = await this.axios.get("/api/admin/pay/config/"+id)
        me.updateCode = true
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
    },
    handlePageChange(val){
      this.codePage.page = val
      this.getCodeList(this.codePage.aisleId)
    },
    handleLimitChange(val){

    },
    addAisleClose(){
      this.addAisle = false
    },
    updateAisleClose(){
      this.updateAisle = false
    },
    addCodeClose(){
      this.addCode = false
    },
    updateCodeClose(){
      this.updateCode = false
    },
    manageCodeClose(){
      this.manageCode = false
    },
    async addAisleSubmit(){
      let me = this
      try{
        let res = await this.axios.form("/api/admin/pay/create/aisle",me.addAisleForm)
        me.$notify.success({ title: "成功", message: "添加成功" });
        me.addAisle = false
        await me.list();
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
    },
    async updateAisleSubmit(id){
      let me = this
      try{
        let res = await this.axios.form("/api/admin/pay/update/aisle/"+id,
          me.updateAisleForm)
        me.$notify.success({ title: "成功", message: "添加成功" });
        me.updateAisle = false
        await me.list();
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
    },
    async addCodeSubmit(){
      let me = this
      try{
        let res = await this.axios.form("/api/admin/pay/created/config/"+me.codePage.aisleId,
          me.addCodeForm)
        me.$notify.success({ title: "成功", message: "添加成功" });
        me.addCode = false
        await me.getCodeList(me.codePage.aisleId);
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
    },
    async updateCodeSubmit(id){
      let me = this
      try{
        let res = await this.axios.form("/api/admin/pay/update/config/"+id,
          me.updateCodeForm)
        me.$notify.success({ title: "成功", message: "添加成功" });
        me.updateCode = false
        await me.getCodeList(me.codePage.aisleId);
      }catch (e){
        me.$notify.error({ title: "错误", message: e.message });
      }
    },
    async deleteAisle(id){
      let me = this
      this.$confirm("确认要删除通道？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios.form("/api/admin/pay/delete/"+id).then((data) => {
            me.$message({ message: "删除成功", type: "success" });
            me.list();
        }).catch((rsp) => {
            me.$notify.error({ title: "错误", message: rsp.message });
        });
      }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
      });
    },
    async deleteCode(id){
      let me = this
      this.$confirm("确认要删除二维码？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios.form("/api/admin/pay/delete/code/"+id).then((data) => {
          me.$message({ message: "删除成功", type: "success" });
          me.getCodeList(me.codePage.aisleId);
        }).catch((rsp) => {
          me.$notify.error({ title: "错误", message: rsp.message });
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    }
  }
}
</script>

<style scoped>

</style>
